import React from 'react'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import {faArrowAltCircleLeft, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface PageWrapper {
    title: string,
    children: any,
    button?: any
}

const PageWrapper = ({title, children, button}: PageWrapper) => (
    <div className={'container-fluid p-5'}>
        <div className={'row justify-content-between align-items-center mb-5'}>
            <h1 className={'font-weight-bold text-uppercase mb-3 h3'}>
                {title}
            </h1>
        </div>

        {
            children
        }

    </div>
)

export default PageWrapper

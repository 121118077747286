import {Button, Card, Col, Form, Input, Modal, Row, Space, Switch, Table, Tabs, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useState, useRef } from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import { io } from 'socket.io-client'

import { useDispatch, useSelector } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartPie, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import FormNeuerAuftrag from '../components/forms/FormNeuerAuftrag'
import Anrufe from '../components/tiles/anrufe'
import TableAuftraege from '../components/tables/auftraege'
import Chatbox from '../components/tables/chatbox'
import CardsAuftraege from "../components/tiles/auftraege";
import Map, {NavigationControl, Marker} from 'react-map-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl'
import { debounce } from "lodash"
import dayjs from 'dayjs'
import { PlusSquareOutlined } from '@ant-design/icons'

const { TabPane } = Tabs

const containerStyle = {
    width: '100%',
    height: '80vh'
}

const center = {
    lat: 52.5224962,
    lng: 13.4441354
}

const socket = io(process.env.NODE_ENV === 'production' ? 'https://kbcc.app.berliner-stadtmission.de' : 'http://localhost:8080')

export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([] as any)
    const [dataSource, setDataSource] = useState(null as any)
    const [defaultMessage, setDefaultMessage] = useState([] as any)
    const user = useSelector(state => state.user)
    const [chat, setChat] = useState(false as any)
    const [time, setTime] = useState(moment().format('HH:mm'))
    const [busse, setBusse] = useState([] as any)
    const [auftraege, setAuftraege] = useState([] as any)
    const [locations, setLocations] = useState([] as any)
    const [visible, setVisible] = useState(false)
    const map = useRef(null as any)

    const { pool }: any = useParams()

    useEffect(() => {
        socket.emit('room', pool, 'CC')
        // console.log(socket.id, user)
    }, [])

    useEffect(() => {
        fetchKB()
        fetchAuftrag()
        fetchOrte()
        fetchLocations()

        socket.on('auftrag', () => fetchAuftrag())
        socket.on('kbuser', (users) => {
            fetchKB()
        })
        socket.on('private message', ({ content, from }) => {
            setChat(from)
            setDefaultMessage(content)
        })

        /*setInterval(() => {
            setTime(moment().format('HH:mm'))
            fetchOrte()
            console.log('UPDATE')
        }, 60000)*/

    }, [])

    const fetchKB = () => {
        axios.get('/pool/schichten/' + pool)
            .then(data => {
                // console.log(data.data)
                setDataSource(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchOrte = () => {
        axios.get(`/schicht/${pool}/standorte`)
            .then(data => {
                setBusse(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchLocations = () => {
        axios.get(`/kaeltehilfe/locations/${pool}`)
            .then(data => {
                setLocations(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchAuftrag = () => {
        axios.get('/auftrag/pool/' + pool)
            .then(data => {
                setAuftraege(data.data)
            })
            .catch(error => console.log(error))
    }

    const debouncedSearch = debounce(async (criteria) => {
        await axios.get('/autocomplete/' + criteria.target.value)
            .then(data => {
                console.log(data.data)
            })
            .catch(error => console.log(error))
    }, 500)

    const sucheAutocomplete = async (val) => {
        debouncedSearch(val)
    }

    const setPrio = (val) => {
        if (val === 1) return 'green'
        if (val === 2) return 'yellow'
        if (val === 3) return 'red'

        return 'ant-tag ant-tag-default'
    }

    const toggleLocations = (data) => {
        if (data) fetchLocations()
        else setLocations([])
    }

    return (
        <div className='d-flex flex-column map-page' style={{height: 'calc(100vh - 60px)'}}>
            <div className='px-3 py-2 mt-2' style={{flex: 1}}>
                <h4 className='m-0 font-weight-bold text-uppercase'>{dayjs(pool).locale('de').format('dddd, Do MMMM YYYY')}</h4>
            </div>
            <div className='d-flex p-3' style={{height: 'calc(100% - 56px)'}}>
                <div style={{width: '350px'}} className='d-flex flex-column'>
                    <div className={'p-3 border-card mb-4 border-card--bordered'} style={{flex: 1}}>
                        <div className={'d-flex justify-content-between'}>
                            <h6 className={'font-weight-bold text-uppercase my-1'}>Aufträge</h6>
                            <Button
                                type="link"
                                icon={<PlusSquareOutlined style={{color: '#e3f0ff'}} />}
                                onClick={() => setVisible(true)}
                            />
                        </div>
                        <div className='overflow-auto' style={{height: 'calc(100% - 32px)'}}>
                            <CardsAuftraege socket={socket} auftraege={auftraege} map={map} />
                        </div>
                    </div>
                    <div className={'pl-3 pt-3 border-card border-card--bordered'}>
                        <h6 className={'font-weight-bold text-uppercase'}>Anrufe</h6>
                        <Anrufe pool={pool} socket={socket} />
                    </div>
                </div>

                <div style={{flex: 2}} className='border-card border-card--shadow ml-4'>
                    <Map
                        ref={map}
                        mapLib={maplibregl as any}
                        initialViewState={{
                            longitude: 13.4441354,
                            latitude: 52.5224962,
                            zoom: 12
                        }}
                        style={{width: "100%", height: "100%"}}
                        mapStyle="https://tiles.locationiq.com/v3/streets/vector.json?key=pk.0f147952a41c555a5b70614039fd148b"
                    >

                        <NavigationControl position="top-right" />
                        <div className='switchForm_map border-card p-3'>
                            <Space><span>Einrichtungen:</span><Switch defaultChecked onChange={toggleLocations} /></Space>
                            <Button className='d-block mt-2' onClick={fetchKB}>Fahrzeuge laden</Button>
                        </div>

                        {
                            auftraege && auftraege.map((item: any, key: number) => (
                                item.gemeldet.ort.geometry && <Marker longitude={item.gemeldet.ort.geometry.location.lng} latitude={item.gemeldet.ort.geometry.location.lat} key={'MARKER_AUFTRAG_' + key}>
                                    <Tag color={setPrio(item?.gemeldet?.prio?.value)} className='p-1 font-weight-bold' style={{opacity: item?.close ? 0.6 : 1}} onClick={() => {
                                        map.current.flyTo({center: [item.gemeldet.ort.geometry.location.lng, item.gemeldet.ort.geometry.location.lat], zoom: 16})
                                    }}>
                                        {moment(item.created_at).format('HH:mm')} / {item?.close ? moment(item?.close).format('HH:mm') : '--'} / {item?.zugewiesen?.name}
                                    </Tag>
                                </Marker>
                            ))
                        }

                        {
                            busse.length > 0 && busse.map((item: any, key: number) => (
                                item.standort && <Marker longitude={item.standort.coords.longitude} latitude={item.standort.coords.latitude} key={'MARKER_BUS_' + key}>
                                    <Tag color='geekblue' className={`p-2 font-weight-bold text-center`} style={{width: '80px'}} onClick={() => {
                                        map.current.flyTo({center: [item.standort.coords.longitude, item.standort.coords.latitude], zoom: 16})
                                    }}>
                                        {item.team.name}
                                    </Tag>
                                </Marker>
                            ))
                        }

                        {
                            locations.length > 0 && locations.map((item: any, key: number) => (

                                <Marker longitude={item.locationLocation.addressLongitude} latitude={item.locationLocation.addressLatitude} key={'MARKER_LOCAL_' + key}>
                                    <Tag color="purple" onClick={() => {
                                        map.current.flyTo({center: [item.locationLocation.addressLongitude, item.locationLocation.addressLatitude], zoom: 16})
                                    }}>
                                        {item.locationName} / {item?.locationGender?.genderName}
                                    </Tag>
                                </Marker>
                            ))
                        }

                    </Map>
                </div>
            </div>

            <Modal visible={visible} width={800} footer={null} destroyOnClose={true} onCancel={() => setVisible(false)}>
                <FormNeuerAuftrag socket={socket} />
            </Modal>

        </div>
    )
}

import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row} from 'antd'
import configuration from '../../configuration'
import axios from 'axios'
import Title from '../title/title'
import {Editor} from '@tinymce/tinymce-react'
import FormElement from './FormElement'
import {useHistory, useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'


const FormNeuesTeam = ({defaultValues = null as any, modalAction = null as any, form, postUrl}) => {

    const [loading, setLoading] = useState(false)

    const [aForm] = Form.useForm()

    const onFinish = (values: any) => {
        setLoading(true)

        if (defaultValues)
            axios.put(postUrl, values)
                .then(data => {
                    setLoading(false)
                    aForm.resetFields()
                    modalAction()
                })
                .catch(error => console.log(error))
        else
            axios.post(postUrl, values)
                .then(data => {
                    setLoading(false)
                    aForm.resetFields()
                    modalAction()
                })
                .catch(error => console.log(error))
    }

    return (
        <Form onFinish={onFinish} form={aForm} layout="vertical">

            <Row gutter={24}>
                {
                    configuration().forms[form].map((formElement: any, i) => {

                        if (formElement.type === 'title') {
                            return <Col span={24} key={i}><Title text={formElement.label} /></Col>
                        } else {
                            return (
                                <Col span={formElement.width || 8} key={i}>
                                    <FormElement formElement={formElement} defaultValue={formElement?.fieldKey ? (!defaultValues?.[formElement?.fieldKey] ? null : defaultValues?.[formElement?.fieldKey][formElement.name] || null) : defaultValues?.[formElement.name]} />
                                </Col>
                            )
                        }

                    })
                }
            </Row>

            <Row className='mt-1'>
                <Col>
                    <Form.Item className={'m-0'}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Speichern
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

        </Form>
    )
}

export default FormNeuesTeam

import React, {Fragment, useEffect, useState} from 'react'
import {Button, Modal, Popconfirm, Space, Table, Tag} from 'antd'
import axios from 'axios'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import FormNeuerAuftrag from '../forms/FormNeuerAuftrag'
import {LngLatLike} from "mapbox-gl";



const CardsAuftraege = ({socket, auftraege = [], map}) => {

    const [data, setData] = useState([] as any)
    const [modalData, setModalData] = useState({visible: false, data: {} as any})
    const [visible, setVisible] = useState(false)
    const { pool }: any = useParams()


    const deleteItem = (item: any) => {
        axios.delete(`/auftrag/${pool}/${item._id}`)
            .then(data => {
                console.log(data.data)
            })
            .catch(error => console.log(error))
    }

    const changeItem = (itemData: any) => {

        let data2 = {...modalData.data} as any

        // console.log(itemData)

        data2.gemeldet = itemData

        if (!itemData.beschreibung && modalData.data?.gemeldet.beschreibung.length)
            data2.gemeldet.beschreibung = modalData.data?.gemeldet.beschreibung

        axios.put(`/auftrag/${data2._id}`, data2)
            .then(data => setModalData({visible: false, data: {}}))
            .catch(error => console.log(error))
    }

    const handleCancel = () => setModalData({visible: false, data: {}})

    const setPrio = (val) => {
        if (val === 1) return 'green'
        if (val === 2) return 'yellow'
        if (val === 3) return 'red'

        return 'ant-tag ant-tag-default mr-0'
    }


    return (
        <>
            {
                auftraege && auftraege.map((item: any) => (
                    <Tag
                        color={setPrio(item?.gemeldet?.prio?.value)}
                        className={`p-2 my-2 d-block`}
                        style={{borderRadius: '4px', boxShadow: '#000d20 0 0 10px', overflow: 'hidden', whiteSpace: 'normal', opacity: item?.close ? 0.6 : 1}}
                        onClick={() => {
                            map.current.flyTo({center: [item.gemeldet.ort.geometry.location.lng, item.gemeldet.ort.geometry.location.lat], zoom: 16})
                        }}
                    >
                        <div className={'schicht-card-content'}>
                            <div className={'d-flex justify-content-between border-bottom pb-1'} style={{fontSize: '14px'}}>
                                <span className={'font-weight-bold'}>
                                    {moment(item.created_at).format('HH:mm')} / {item?.close ? moment(item?.close).format('HH:mm') : '--'}
                                </span>
                                <span style={{whiteSpace: 'nowrap'}}>{item?.zugewiesen?.name}</span>
                            </div>

                            <div className={'border-bottom py-1'} style={{fontSize: '12px'}}>
                                <span className={'font-weight-bold'}>{item.gemeldet.ort.name ? `${item.gemeldet.ort.name} / ${item.gemeldet.ort.formatted_address}` : item.gemeldet.ort.formatted_address}</span>
                            </div>
                            <div className={'mt-1'} style={{fontSize: '12px'}}>
                                <span className={'font-italic'}>{item.gemeldet?.beschreibung}</span>
                            </div>
                        </div>
                    </Tag>
                ))
            }

            <Modal
                title="Auftrag bearbeiten"
                visible={modalData.visible}
                onCancel={handleCancel}
                footer={null}
                width={1000}
                destroyOnClose={true}
            >
                <FormNeuerAuftrag socket={socket} defaultValues={modalData.data} modalAction={changeItem} />
            </Modal>
        </>
    )
}

export default CardsAuftraege

import React, {useState} from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import {setMenuView} from '../../store/actions'
import Menu from '../menu/menu'


const Header = () => {

    
    const { keycloak, initialized } = useKeycloak()
    const user = useSelector(state => state.user.user)
    const [menu, setMenu] = useState(false)
    const dispatch = useDispatch()

    return (
        <>
            <header className={'c-header'}>
                <div className={'c-header__inner d-flex align-items-center justify-content-between'}>
                    <div>
                        <button className='btn text-white' onClick={() => setMenu(!menu)}><FontAwesomeIcon icon={faBars} /></button>
                    </div>
                    <div className={'c-header__user-text text-uppercase'}>
                        <span className={'bold d-none d-sm-inline'}>
                            {user.name}
                        </span>
                        <span className={'c-header__location-text d-none d-sm-inline'}>
                            Kältebus
                        </span>
                        {!!keycloak.authenticated && (
                            <FontAwesomeIcon className={'logout'} onClick={() => keycloak.logout()}  icon={faSignOutAlt} />
                        )}
                    </div>
                </div>
            </header>
            <Menu visible={menu} close={() => setMenu(false)} />
        </>
    )
}

export default Header;

import {SET_LOADING} from '../constants'

const INITIAL_STATE = {
    visible: false
}

function loadingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                visible: action.payload
            }
        default: return state
    }
}

export default loadingReducer

import {
    FETCH_USER_BEGIN,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    SET_MENU_VIEW
} from '../constants'
import axios from 'axios'

export const setUserData = (user) => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: user
    }
}

export const setMenuView = (data) => {
    return {
        type: SET_MENU_VIEW,
        payload: data
    }
}

import React from 'react'
import configuration from '../../configuration'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

interface Menu {
    visible: Boolean,
    close: any
}

const Menu = ({visible, close}: Menu) => {
    
    const user = useSelector(state => state.user.user)

    return (
        <nav className={!visible ? 'c-navigation c-navigation--hidden' : 'c-navigation'}>
            <ul>
                {
                    configuration().routes.map((item: any, i: number) => (
                        <li key={i} onClick={close}><Link to={item.url}>{item.title}</Link></li>
                    ))
                }

                
                {
                    user.realm_access.roles.includes('BSM') && configuration().BSM_Routes.map((item: any, i: number) => (
                        <li key={i} onClick={close}><Link to={item.url}>{item.title}</Link></li>
                    ))
                }
            </ul>
        </nav>
    )
}


export default Menu
// @flow
import React, {StrictMode, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {ReactKeycloakProvider} from '@react-keycloak/web'
import axios from 'axios'

import keycloak from '../../keycloak'
import {AppRouter} from '../../routes'
import {setUserData} from "../../store/actions";
import { AppRouterOTP } from '../../routes/ehrenamtOTP'
import { useLocation } from 'react-router-dom'

const config: object = {
    onLoad: 'login-required',
    checkLoginIframe: false
}

const KeycloakWrapper = () => {

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const onKeycloakEvent = (event, error) => {
        console.log(event)
        if (event === 'onReady') {
            keycloak.loadUserInfo().then(userInfo => {
                console.log('user')
                axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
                axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000/api'
                
                dispatch(setUserData(userInfo))

            }).catch(err => console.log(err))

            setInterval(() => {
                keycloak.updateToken(180).then((refreshed) => {
                    if (!refreshed) console.log('Token not refreshed', refreshed)
                }).catch(() => {
                    console.error('Failed to refresh token')
                    keycloak.logout()
                })
            }, 60000)

        } else if (event === 'onTokenExpired') {
            keycloak.logout()
        }
    }

    const onKeycloakTokens = (tokens) => {
        // console.log('onKeycloakTokens', tokens)
        axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`
        axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000/api'
    }

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={config}
            onEvent={onKeycloakEvent}
            onTokens={onKeycloakTokens}
        >
            
                {
                    user.loading ? (
                        <div className={'loader'} />
                    ) : (
                        user.user.realm_access.roles.includes('ehrenamt') ? (
                            <AppRouterOTP />
                        ) : (
                            <AppRouter />
                        )
                    )
                }

        </ReactKeycloakProvider>
    )
}

export default KeycloakWrapper

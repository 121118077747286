import { combineReducers } from 'redux'
import userReducer from './user'
import menuReducer from './menu'
import loadingReducer from './loading'

const rootReducer = combineReducers({
    // screen routing
    user: userReducer,
    menu: menuReducer,
    loading: loadingReducer
})

export default rootReducer

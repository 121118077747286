import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom'
import { EmbedSignDocument } from '@documenso/embed-react'


export default () => {
    
    const { id }: any = useParams()

    return (
        <div className='d-flex flex-column' style={{height: '100dvh'}}>
            <div className='p-3'>
                <Link to={'/'}>Zurück</Link>
            </div>
            <div style={{flex: 1}}>
                <EmbedSignDocument 
                    token={id}
                    host={process.env.REACT_APP_DOCUMENSO_URL || 'https://documenso.app.berliner-stadtmission.de'}
                    css='border: none'
                    className='d-block w-100 h-100'
                />
            </div>
        </div>
    )
}

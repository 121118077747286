import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Row} from 'antd'
import configuration from '../../configuration'
import axios from 'axios'
import Title from '../title/title'
import {Editor} from '@tinymce/tinymce-react'
import FormElement from './FormElement'
import {useHistory, useParams} from 'react-router-dom'
import { useSelector } from 'react-redux'


const FormNeuerAuftrag = ({defaultValues = null as any, modalAction = null as any, socket}) => {

    const { pool }: any = useParams()

    const [form, setForm] = useState(null as any)
    const [ort, setOrt] = useState(defaultValues?.gemeldet?.ort || {})
    const [loading, setLoading] = useState(false)
    const [beschreibung, setBeschreibung] = useState('')

    const [aForm] = Form.useForm()

    useEffect(() => {
        let formValues = configuration().forms.auftrag_erfassen.standard

        if (defaultValues)
            aForm.setFieldsValue({
                'beschreibung' : defaultValues?.gemeldet?.beschreibung
            })

        axios.post('/form', formValues)
            .then(data => setForm(data))
            .catch(error => console.log(error))
    }, [])

    const onFinish = (values: any) => {
        setLoading(true)

        // console.log(values)

        values.ort = ort
        values.beschreibung = beschreibung

        if (!modalAction) {
            axios.post('/auftrag/erfassen', {pool, gemeldet: values})
                .then(data => {
                    setLoading(false)
                    aForm.resetFields()
                    setBeschreibung('')
                })
                .catch(error => console.log(error))
        } else {
            modalAction(values)
            setLoading(false)
        }
    }

    return (
        <Form onFinish={onFinish} form={aForm} layout="vertical">

            <Row gutter={24}>
                {
                    form && form.data.map((formElement: any, i) => {

                        if (formElement.type === 'title') {
                            return <Col span={24} key={i}><Title text={formElement.label} /></Col>
                        } else {
                            return (
                                <Col xs={{span: 24}} md={{span: formElement.width || 8}} key={i}>
                                    <FormElement formElement={formElement} defaultValue={defaultValues?.gemeldet?.[formElement.name]} autocomplete={setOrt} onChange={(e: any) => setBeschreibung(e.target.value)} />
                                </Col>
                            )
                        }

                    })
                }
            </Row>

            <Row className='mt-1'>
                <Col>
                    <Form.Item className={'m-0'}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Speichern
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

        </Form>
    )
}

export default FormNeuerAuftrag

import {Button, Card, DatePicker, Form, Input, Space, Table, Tag} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/de_DE'

import { useDispatch } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import axios from 'axios'


export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const onFinish = (values: any) => {
        console.log(values.datum.format('YYYYMMDD'))
        history.push('/auftrag/pool/' + values.datum.format('YYYYMMDD'))
    }

    moment.locale('de')

    return (
        <PageWrapper title={''}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-12 text-center'}>
                        <h4 className={'font-weight-bold text-uppercase mb-4'}>Bitte wähle einen Tag aus:</h4>
                        <Form onFinish={onFinish} layout="vertical">
                            <Space direction={'vertical'}>
                                <Form.Item name={'datum'}>
                                    <DatePicker name={'datum'} size={'large'} locale={locale} format={'DD.MM.YYYY'} />
                                </Form.Item>
                                <Button type="primary" htmlType="submit">Los</Button>
                            </Space>
                        </Form>
                    </div>
                </div>
            </div>
        </PageWrapper>
    )
}

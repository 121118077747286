import React, {useEffect, useState} from 'react'
import {Button, Card, Input, Space, Table, Tag} from 'antd'
import axios from 'axios'
import moment from 'moment'
import {useParams} from 'react-router-dom'



const Chatbox = ({user, socket, close, defaultMessages = []}) => {

    const [data, setData] = useState(user)
    const [messages, setMessages] = useState(defaultMessages as any)
    const { pool }: any = useParams()

    const submit = (value) => {

        setMessages([...messages, {
            from: 'CC',
            time: Date.now(),
            message: value
        }])

        socket.emit('private message', {
            content: [...messages, {
                from: 'CC',
                time: Date.now(),
                message: value
            }],
            to: user.key,
        })
    }

    return (
        <div className={'o-chat-wrapper'}>
            <div className={'c-chat-item'}>
                <Card type="inner" title={data?.name} extra={<Button onClick={close}>Schließen</Button>}>
                    <ul className={'c-chat-item__list'}>
                        {
                            messages.map((item: any) => (
                                item.from === 'CC' ? (
                                    <li key={item.time} className={'clearfix'}>
                                        <span className={'e-chat e-chat__me'}>{item.message}</span>
                                    </li>
                                ) : (
                                    <li key={item.time} className={'clearfix'}>
                                        <span className={'e-chat e-chat__you'}>{item.message}</span>
                                    </li>
                                )

                            ))
                        }
                    </ul>
                </Card>
                <div className={'py-2 px-3 bg-white'}>
                    <Input placeholder="Tippe eine Nachricht..." bordered={false} onPressEnter={(data: any) => submit(data?.target?.value)} />
                </div>
            </div>
        </div>
    )
}

export default Chatbox

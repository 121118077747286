import { useKeycloak } from '@react-keycloak/web'
import {Button, Card, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Space, Statistic, Table, Tag, Switch, Grid, List, Collapse} from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom'
import { useSelector } from 'react-redux'



export default () => {
    
    const [data, setData] = useState({} as any)
    const user = useSelector(state => state.user.user)
    const { keycloak, initialized } = useKeycloak()

    useEffect(() => {
        getEA()
    }, [])

    const getEA = () => {
        axios.get('/ehrenamt')
            .then((data) => setData(data.data))
            .catch(error => console.log(error))
    }


    return (
        <div className='p-4'>
            <h3>{user.name}</h3>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Card title={'Zeiten'}>

                        <Collapse>

                            {
                                data.times && data.times.map((item: any) => (
                                    
                                    <Collapse.Panel header={item.title} key={item.title}>

                                        <List
                                            dataSource={item.data}
                                            renderItem={(time: any) => (
                                                <List.Item>
                                                    <div className=''>
                                                        {time.zeit_von} - <br />
                                                        {time.zeit_bis}
                                                    </div>
                                                </List.Item>
                                            )}
                                        />

                                    </Collapse.Panel>

                                ))
                            }

                        </Collapse>

                    </Card>
                </Col>
                
                <Col span={24}>
                    <Card title={'Vereinbarung Ehrenamt'}>
                        <List
                            dataSource={data.vereinbarungen}
                            renderItem={(item: any) => (
                                <List.Item
                                    actions={[<Link to={'/vereinbarung/' + item.token}>Öffnen</Link>]}
                                >
                                    <div>
                                        <div className='font-weight-bold'>
                                            {item.monat}
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
                
                <Col span={24}>
                    <Button size='large' type='dashed' block danger onClick={() => keycloak.logout()}>Abmelden</Button>
                </Col>
            </Row>

        </div>
    )
}

import {SET_MENU_VIEW} from '../constants'

const INITIAL_STATE = {
    visible: false
}

function menuReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_MENU_VIEW:
            return {
                ...state,
                visible: action.payload
            }
        default: return state
    }
}

export default menuReducer

import {Button, Card, DatePicker, Form, Input, message, Modal, Popconfirm, Space, Table, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import { useDispatch } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import axios from 'axios'
import FormNeuerAuftrag from "../components/forms/FormNeuerAuftrag";
import FormNeuesTeam from "../components/forms/FormNeuesTeam";
import FormElement from "../components/forms/FormElement";
import {Editor} from '@tinymce/tinymce-react'
import SignaturePad from 'react-signature-pad-wrapper'
import { useSelector } from 'react-redux'

export default () => {

    const user = useSelector(state => state.user.user)
    const history = useHistory()
    const [visible, setVisible] = useState({state: false, url: '', form: ''})
    const [teams, setTeams] = useState([] as any)
    const [values, setValues] = useState(null as any)
    const [signId, setSignId] = useState('')
    const [fahrzeuge, setFahrzeuge] = useState([] as any)
    const [infos, setInfos] = useState({} as any)
    const [loading, setLoading] = useState(true)
    const editorRef = useRef(null as any)
    const signRef = useRef(null as any)


    const getTeams = () => {
        axios.get('/teams')
            .then(data => setTeams(data.data))
            .catch(error => console.log(error))
    }

    const getFahrzeuge = () => {
        axios.get('/fahrzeuge')
            .then(data => setFahrzeuge(data.data))
            .catch(error => console.log(error))
    }

    const getData = () => {
        setValues(null)
        setVisible({state: false, url: '', form: ''})

        if (visible.form === 'neuesTeam')
            getTeams()
        else
            getFahrzeuge()
    }

    useEffect(() => {
        getFahrzeuge()
        getTeams()
        getInfos()
        getSign()
    }, [])

    const change = (data, url, form) => {
        setValues(data)
        setVisible({state: true, url, form})
    }

    const deleteItem = (item: any, art) => {
        axios.delete(`/${art}/${item._id}`)
            .then(data => {
                if (art === 'team')
                    getTeams()
                else
                    getFahrzeuge()
            })
            .catch(error => console.log(error))
    }

    const saveInfos = (val: any) => {

        if (infos._id) {
            val = {
                _id: infos._id,
                infos: editorRef.current.getContent()
            }
        } else {
            val = {
                infos: editorRef.current.getContent()
            }
        }

        axios.post(`/settings/infos` + (infos?._id ? `/${infos._id}` : ''), val)
            .then(data => {
                console.log('SAVE')
                message.success('Gespeichert.')
            })
            .catch(error => console.log(error))
    }

    const getInfos = () => {
        axios.get(`/settings/infos`)
            .then(data => {
                setInfos(data.data)
                setLoading(false)
            })
            .catch(error => console.log(error))
    }

    const getSign = () => {
        axios.get(`/sign/${user.sub}`)
            .then(data => {
                setSignId(data.data._id)
                signRef.current.signaturePad.fromDataURL(data.data.data)
            })
            .catch(error => console.log(error))
    }

    const saveSign = () => {
        if (signId.length) {
            axios.put(`/sign/` + signId, {data: signRef.current.signaturePad.toDataURL()})
                .then(data => {
                    console.log('SAVE')
                    message.success('Gespeichert.')
                })
                .catch(error => console.log(error))
        } else {
            axios.post(`/sign`, {user: user.sub, data: signRef.current.signaturePad.toDataURL()})
                .then(data => {
                    console.log('SAVE')
                    message.success('Gespeichert.')
                })
                .catch(error => console.log(error))
        }
    }


    const remSign = () => {
        signRef.current.signaturePad.clear()
        axios.delete(`/sign/` + signId)
            .then(data => {
                message.success('Gespeichert.')
                setSignId('')
            })
            .catch(error => console.log(error))
    }

    return (
        <PageWrapper title={'Einstellungen'}>
            <div className={'container'}>
            
                <div className={'row mb-4'}>
                    <div className={'col'}>
                        <Card title="Unterschrift" bordered type='inner' extra={
                            <Space>
                                <Button onClick={remSign} danger type='link'>Löschen</Button>
                                <Button onClick={saveSign}>Speichern</Button>
                            </Space>
                        }>
                            <div className='border signPad'>
                                <SignaturePad ref={signRef} height={300}/>
                            </div>
                        </Card>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col'}>
                        <Card title="Teams" extra={<Button onClick={() => setVisible({state: true, url: '/team/neu', form: 'neuesTeam'})}>Team hinzufügen</Button>}>
                            {
                                <Table pagination={false} dataSource={teams} rowKey={'_id'} columns={[
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: 'Telefon',
                                        dataIndex: 'telefon',
                                        key: 'telefon',
                                    },
                                    {
                                        title: 'Bemerkung',
                                        dataIndex: 'bemerkung',
                                        key: 'bemerkung',
                                    },
                                    {
                                        title: 'Aktion',
                                        key: 'action',
                                        width: 300,
                                        render: (text, elem: any) => (
                                            <>
                                                <Button type={'link'} onClick={() => change(elem, '/team/' + elem._id, 'neuesTeam')}>Bearbeiten</Button>
                                                <Popconfirm
                                                    title="Team löschen?"
                                                    onConfirm={() => deleteItem(elem, 'team')}
                                                    okText="Ja"
                                                    cancelText="Nein"
                                                >
                                                    <Button type={'link'} danger>Löschen</Button>
                                                </Popconfirm>
                                            </>
                                        ),
                                    },
                                ]} />
                            }
                        </Card>
                    </div>
                </div>
                <div className={'row mt-4'}>
                    <div className={'col'}>
                        <Card title="Fahrzeuge" extra={<Button onClick={() => setVisible({state: true, url: '/fahrzeug/neu', form: 'neuesFahrzeug'})}>Fahrzeug hinzufügen</Button>}>
                            {
                            <Table pagination={false} dataSource={fahrzeuge}
                                   expandable={{
                                       expandedRowRender: (record: any)=> <div className={'p-4'}><Table
                                           pagination={false}
                                           dataSource={record?.schadensliste}
                                           columns={[
                                               {
                                                   title: 'Datum',
                                                   dataIndex: 'datum',
                                                   key: 'datum',
                                                   width: 100,
                                                   render: (text, elem: any) => moment(text).format('DD.MM.YYYY'),
                                               },
                                               {
                                                   title: 'Beschreibung - Was ist kaputt?',
                                                   dataIndex: 'beschreibung',
                                                   key: 'beschreibung',
                                               },
                                               {
                                                   title: 'Aktion',
                                                   key: 'action',
                                                   width: 200,
                                                   render: (text, elem: any) => (
                                                       <Popconfirm
                                                           title="Schaden löschen?"
                                                           onConfirm={() => deleteItem(elem, 'schaden')}
                                                           okText="Ja"
                                                           cancelText="Nein"
                                                       >
                                                           <Button type={'link'} danger>Löschen</Button>
                                                       </Popconfirm>
                                                   ),
                                               },
                                           ]}
                                       /></div>,
                                       rowExpandable: record => record?.schadensliste.length,
                                   }}
                                   rowKey={'_id'}
                                   columns={[
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: 'Kennzeichen',
                                        dataIndex: 'kennzeichen',
                                        key: 'kennzeichen',
                                    },
                                    {
                                        title: 'Aktion',
                                        key: 'action',
                                        width: 300,
                                        render: (text, elem: any) => (
                                            <>
                                                <Button type={'link'} onClick={() => change(elem, '/fahrzeug/' + elem._id, 'neuesFahrzeug')}>Bearbeiten</Button>
                                                <Popconfirm
                                                    title="Fahrzeug löschen?"
                                                    onConfirm={() => deleteItem(elem, 'fahrzeug')}
                                                    okText="Ja"
                                                    cancelText="Nein"
                                                >
                                                    <Button type={'link'} danger>Löschen</Button>
                                                </Popconfirm>
                                            </>
                                        ),
                                    },
                                ]} />
                            }
                        </Card>
                    </div>
                </div>
                <div className={'row mt-4'}>
                    <div className={'col'}>
                        <Card title="KB-Infos in App">
                            {
                                !loading &&
                                    <div>
                                        <Editor
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            apiKey={'cgurp8vodxcbclghg74z1b4chalceg53jcw2sqwz8ylkx5j9'}
                                            initialValue={infos.infos}
                                            plugins={[
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'code', 'help'
                                            ]}
                                            toolbar={
                                                'hr | bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help'
                                            }
                                            init={{
                                                height: 600,
                                                menubar: false,
                                            }}
                                        />
                                        <Button className={'mt-3 float-right'} type={'primary'} onClick={saveInfos}>Speichern</Button>
                                    </div>
                            }
                        </Card>
                    </div>
                </div>
            </div>
            <Modal
                title={`Neues Element hinzufügen`}
                visible={visible.state}
                onCancel={() => {
                    setValues(null)
                    setVisible({state: false, url: '', form: ''})
                }}
                footer={null}
                width={'50vw'}
                destroyOnClose={true}
            >
                <FormNeuesTeam form={visible.form} defaultValues={values} postUrl={visible.url} modalAction={() => getData()} />
            </Modal>
        </PageWrapper>
    )
}

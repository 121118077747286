import React, {useEffect, useState} from 'react'
import {Avatar, Badge, Button} from 'antd'
import configuration from '../../configuration'
import axios from 'axios'

const Anrufe = ({pool, socket}) => {

    const [form, setForm] = useState(null as any)
    const [anrufe, setAnrufe] = useState([] as any)

    useEffect(() => {
        let formValues = configuration().forms.anrufer

        axios.post('/form', formValues)
            .then(data => setForm(data.data))
            .catch(error => console.log(error))

        fetchAnrufe()
        socket.on('anrufe', () => fetchAnrufe())

    }, [])

    const fetchAnrufe = () => {
        axios.get('/anrufe/' + pool)
            .then(data => setAnrufe(data.data))
            .catch(error => console.log(error))
    }

    const pushAnruf = (id) => {
        const isAnruf = anrufe.find(anruf => anruf?.typ === id)

        if (!isAnruf) {
            axios.post('/anrufe/' + pool, {pool, typ: id, anzahl: 1})
                .then(data => {})
                .catch(error => console.log(error))
        } else {
            axios.put(`/anrufe/${isAnruf._id}`, {anzahl: isAnruf.anzahl + 1, pool})
                .then(data => {})
                .catch(error => console.log(error))
        }
    }

    return (
        <>
            {
                form && form[0].fields.map((item: any, i: number) => (
                    <div key={i + '_ANRUF_ITEM'} className={'mr-3 mb-3 float-left'}>
                        <Badge count={anrufe.find(anruf => anruf?.typ === item._id)?.anzahl || 0}>
                            <Button type="dashed" onClick={() => pushAnruf(item._id)}>{item.label}</Button>
                        </Badge>
                    </div>
                ))
            }
        </>
    )
}

export default Anrufe

// @flow

import React, {useState} from 'react'
import { Provider } from 'react-redux'

import store from './store'
import KeycloakWrapper from "./components/wrapper/keycloak"

const App = () => (
    <Provider store={store}>
        <KeycloakWrapper />
    </Provider>
)

export default App

import React from 'react'
import {Alert, Table} from 'antd'
import moment from 'moment'

import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'


const TableAuftraegeStatistik = ({data}) => {


    const columns = [
        {
            title: 'Zeit',
            key: 'time',
            width: '10%',
            render: (item: any) => (
                <span>
                    {moment(item.created_at).format('HH:mm')} / {item?.close ? moment(item?.close).format('HH:mm') : '--'}
                </span>
            )
        },
        {
            title: 'Zugewiesen',
            dataIndex: 'zugewiesen',
            key: 'zugewiesen',
            width: '10%',
            render: (item: any) => item?.name
        },
        {
            title: 'Ort',
            dataIndex: ['gemeldet', 'ort'],
            key: 'ort',
            width: '30%',
            render: (item: any, data: any) => (
                <div>
                    {item.name ? `${item.name} / ${item.formatted_address}` : item.formatted_address}

                    {
                        data.angetroffen?.bemerkung && <Alert className={'mt-2'} message={data.angetroffen?.bemerkung} />
                    }
                </div>
            )
        },
        {
            title: 'Ziel',
            dataIndex: ['angetroffen', 'fahrt', 'ziel'],
            key: 'ziel',
            width: '30%'
        },
        {
            title: `Angetroffen (m/w | R m/w)`,
            key: 'angetroffen',
            width: '10%',
            render: item => (
                <>
                    <div>{item.angetroffen?.nichtBeeintraechtigt?.männlich || 0} / {item.angetroffen?.nichtBeeintraechtigt?.weiblich || 0} | {item.angetroffen?.beeintraechtigt?.männlich || 0} / {item.angetroffen?.beeintraechtigt?.weiblich || 0}</div>
                </>
            )
        },
        {
            title: 'Gefahren (m/w | R m/w)',
            key: 'gefahren',
            width: '10%',
            render: item => (
                <>
                    <div>{item.angetroffen?.fahrt?.nichtBeeintraechtigt?.männlich || 0} / {item.angetroffen?.fahrt?.nichtBeeintraechtigt?.weiblich || 0} | {item.angetroffen?.fahrt?.beeintraechtigt?.männlich || 0} / {item.angetroffen?.fahrt?.beeintraechtigt?.weiblich || 0}</div>
                </>
            )
        },
        {
            title: 'Schlafsack / Iso-Matte ausgegeben',
            key: 'ausgabe',
            width: '10%',
            render: item => (
                <>
                    <div>{item.angetroffen?.versorgt?.schlafsack || 0}</div>
                </>
            )
        }
    ]

    return data.length && <Table dataSource={data} columns={columns} pagination={false} rowKey={'_id'} />
}

export default TableAuftraegeStatistik

import {Button, Card, Form, Input, Space, Table, Tabs, Tag} from 'antd'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import { io } from 'socket.io-client'

import { useDispatch, useSelector } from 'react-redux'

import PageWrapper from '../components/wrapper/page'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartPie, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import FormNeuerAuftrag from '../components/forms/FormNeuerAuftrag'
import Anrufe from '../components/tiles/anrufe'
import TableAuftraege from '../components/tables/auftraege'
import Chatbox from '../components/tables/chatbox'
import {GoogleMap, InfoBox, Marker} from "@react-google-maps/api";

const { TabPane } = Tabs

const containerStyle = {
    width: '100%',
    height: '80vh'
}

const center = {
    lat: 52.5224962,
    lng: 13.4441354
}

const socket = io(process.env.NODE_ENV === 'production' ? 'https://kbcc.app.berliner-stadtmission.de' : 'http://localhost:8080')

export default () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([] as any)
    const [dataSource, setDataSource] = useState(null as any)
    const [defaultMessage, setDefaultMessage] = useState([] as any)
    const user = useSelector(state => state.user)
    const [chat, setChat] = useState(false as any)
    const [busse, setBusse] = useState([] as any)
    const { pool }: any = useParams()

    useEffect(() => {
        socket.emit('room', pool, 'CC')
        // console.log(socket.id, user)
    }, [])

    useEffect(() => {
        fetchKB()
        socket.on('kbuser', (users) => {
            fetchKB()
        })
        socket.on('private message', ({ content, from }) => {
            setChat(from)
            setDefaultMessage(content)
        })
    }, [])

    const fetchKB = () => {
        axios.get('/pool/schichten/' + pool)
            .then(data => {
                // console.log(data.data)
                setDataSource(data.data)
            })
            .catch(error => console.log(error))
    }

    const fetchOrte = () => {
        axios.get(`/schicht/${pool}/standorte`)
            .then(data => {
                setBusse(data.data)
            })
            .catch(error => console.log(error))
    }

    const getStandort = (id) => {
        axios.get(`/schicht/${id}/standort`)
            .then(data => {

            })
            .catch(error => console.log(error))
    }

    const [map, setMap] = useState(null)

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = useCallback((map) => {
        setMap(null)
    }, [])

    const columns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'team',
            render: (text) => `${text?.name.toUpperCase()}`
        },
        {
            title: 'Telefon',
            key: 'phone',
            dataIndex: 'team',
            render: (text) => `${text?.telefon}`
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text, record) => <Tag color={'geekblue'}>
                {text.status?.label}
            </Tag>
        },
    ]

    return (
        <PageWrapper title={`${moment(pool).format('dddd, Do MMMM YYYY')}`}>
            <div className={'row'}>
                <div className={'col-md-8 mb-4'}>
                    <Card title="Auftrag erfassen" extra={<a href="#">Zur Übersicht</a>}>
                        <FormNeuerAuftrag socket={socket} />
                    </Card>
                </div>
                <div className={'col-md-4'}>
                    <Card title="Anruf erfassen">
                        <Anrufe pool={pool} socket={socket} />
                    </Card>
                    <Card title="Fahrzeuge" className={'mt-4'}>
                        <Table scroll={{x: true}} dataSource={dataSource} columns={columns} pagination={false} showHeader={false} />
                    </Card>
                </div>
            </div>
            <div className={'row mt-4'}>
                <div className={'col'}>
                    <Card title="Aufträge" extra={<Button onClick={() => history.push(`/auftrag/pool/${pool}/karte`)} type={'link'}>Zur Kartenansicht</Button>}>
                        <Tabs type="card" onChange={(val) => val === 'map' && fetchOrte()}>
                            <TabPane tab="Liste" key="1">
                                <TableAuftraege socket={socket} />
                            </TabPane>
                            <TabPane tab="Karte" key="map">
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    options={{
                                        draggable: true, // make map draggable
                                        zoomControlOptions: { position: 9 },
                                        keyboardShortcuts: true, // disable keyboard shortcuts
                                        scaleControl: true, // allow scale controle
                                        scrollwheel: true, // allow scroll wheel
                                        zoomControl: true,
                                        minZoom: 3,
                                        maxZoom: 12,
                                    }}
                                >

                                    {
                                        busse.map((bus: any) => (
                                            <InfoBox
                                                position={new google.maps.LatLng({
                                                    lat: bus.standort.coords.latitude,
                                                    lng: bus.standort.coords.longitude
                                                })}
                                                options={{ closeBoxURL: ``, enableEventPropagation: true }}
                                            >
                                                <div
                                                    onClick={() => getStandort(bus._id)}
                                                    style={{ backgroundColor: `blue`, opacity: 0.75, padding: `12px`, color: '#fff' }}>
                                                    {bus.team.name}
                                                </div>
                                            </InfoBox>
                                        ))
                                    }

                                </GoogleMap>
                            </TabPane>
                        </Tabs>
                    </Card>
                </div>
            </div>
            {
                chat && <Chatbox user={chat} socket={socket} defaultMessages={defaultMessage} close={() => setChat(false)} />
            }
        </PageWrapper>
    )
}
